import { Country } from '@app/models/country.model';

export const environment = {
  production: true,
  country: Country.DO,
  hybrisOrigin: 'https://www.amway.com.do',
  siteId: 'lynx-do',
};

export const EMAIL_CONFIG = {
  to: 'Customer.Service@amway.com',
};

export const AUTH_CONFIG = {
  host: 'prodapi.apigtwy.amer.amway.net',
  port: 443,
};
